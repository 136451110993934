import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    this.interval = setInterval(() => {
      window.location.reload()
    }, 5000) // 5000 milliseconds = 5 seconds
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
